import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

import Loadable from '../layouts/loader/Loadable';
import ProtectRouteSuperAdmin from '../components/ProtectRouteSuperAdmin';
import ProtectRoute from '../components/ProtectRoute';
import ProtectRouteSuper from '../components/ProtectRouteSuper';
import ProtectRouteDistributor from '../components/ProtectRouteDistributor';
import ProtectRouteAgent from '../components/ProtectRouteAgent';
import ProtectRouteBusiness from '../components/ProtectRouteBusiness';
import ProtectRouteMaster from '../components/ProtectRouteMaster';
import ProtectRouteEmploye from '../components/ProtectRouteEmploye';

/****Layouts*****/
const FullLayout = Loadable(lazy(() => import('../layouts/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/BlankLayout')));
const AdminLayout = Loadable(lazy(() => import('../layouts/AdminLayout')));
const SuperLayout = Loadable(lazy(() => import('../layouts/SuperLayout')));
const DistributorLayout = Loadable(lazy(() => import('../layouts/DistributorLayout')));
const BusinessLayout = Loadable(lazy(() => import('../layouts/BusinessLayout')));
const MasterLayout = Loadable(lazy(() => import('../layouts/MasterLayout')));
const EmployeLayout = Loadable(lazy(() => import('../layouts/EmployeLayout')));
const SuperAdminLayout = Loadable(lazy(() => import('../layouts/SuperAdminLayout')));
/***** Pages ****/

/******super admin pages *******/
const SuperAdminDashboard = Loadable(lazy(() => import('../views/Dashboards/SuperAdminDashboard')));
const AllApi = Loadable(lazy(() => import('../views/SuperAdmin/ApiMaster/AllApi')));
const AddApi = Loadable(lazy(() => import('../views/SuperAdmin/ApiMaster/AddApi')));
const UpdateApi = Loadable(lazy(() => import('../views/SuperAdmin/ApiMaster/UpdateApi')));
const AllServices = Loadable(lazy(() => import('../views/SuperAdmin/ServiceMaster/AllServices')));
const AddServices = Loadable(lazy(() => import('../views/SuperAdmin/ServiceMaster/AddServices')));
const CreateCommissionMasterTemplate = Loadable(
  lazy(() => import('../views/SuperAdmin/CommissionMaster/CreateCommissionMasterTemplate')),
);
const GetCommissionMasterTemplate = Loadable(
  lazy(() => import('../views/SuperAdmin/CommissionMaster/GetCommissionMasterTemplate')),
);
/******Admin Pages ******/
const AdminDashboard = Loadable(lazy(() => import('../views/Dashboards/AdminDashboard')));

// profile
const ProfileUpdate = Loadable(lazy(() => import('../views/Admin/Profile/ProfileUpdate')));
const ChangesPasswordAd = Loadable(lazy(() => import('../views/Admin/Profile/ChangesPasswordAd')));


/*******UserManagement */
const SuperDistributor = Loadable(
  lazy(() => import('../views/Admin/UserManagement/SuperDistributor/SuperDistributor')),
);
const AddSuperDistributor = Loadable(
  lazy(() => import('../views/Admin/UserManagement/SuperDistributor/AddSuperDistributor')),
);
const MappingSuperDistributor = Loadable(
  lazy(() => import('../views/Admin/UserManagement/SuperDistributor/MappingSuperDistributor')),
);
const Distributor = Loadable(
  lazy(() => import('../views/Admin/UserManagement/Distributor/Distributor')),
);
const AddDistributor = Loadable(
  lazy(() => import('../views/Admin/UserManagement/Distributor/AddDistributor')),
);
const MappingDistributor = Loadable(
  lazy(() => import('../views/Admin/UserManagement/Distributor/MappingDistributor')),
);
const AdminAPIUserMaster = Loadable(lazy(() => import('../views/Admin/UserManagement/AdminAPIUserMaster')));
const Retailer = Loadable(lazy(() => import('../views/Admin/UserManagement/Retailer/Retailer')));
const AddRetailer = Loadable(
  lazy(() => import('../views/Admin/UserManagement/Retailer/AddRetailer')),
);
const PendingKycVerification = Loadable(
  lazy(() => import('../views/Admin/UserManagement/Retailer/PendingKycVerification')),
);
const UserServiceStatus = Loadable(
  lazy(() => import('../views/Admin/UserManagement/Retailer/UserServiceStatus')),
);

const MappingRetailer = Loadable(
  lazy(() => import('../views/Admin/UserManagement/Retailer/MappingRetailer')),
);
const UpdateProfileMenu = Loadable(
  lazy(() => import('../views/Admin/UserManagement/UpdateProfileMenu')),
);


const AllRole = Loadable(lazy(() => import('../views/Admin/UserManagement/Role/AllRole')));
const AddNewRole = Loadable(lazy(() => import('../views/Admin/UserManagement/Role/AddNewRole')));

const BusinessPartner = Loadable(
  lazy(() => import('../views/Admin/UserManagement/Business/BusinessPartner')),
);
const AddBusiness = Loadable(
  lazy(() => import('../views/Admin/UserManagement/Business/AddBusiness')),
);

const MasterDistributor = Loadable(
  lazy(() => import('../views/Admin/UserManagement/MasterDistributor/MasterDistributor')),
);
const AddMasterDistributor = Loadable(
  lazy(() => import('../views/Admin/UserManagement/MasterDistributor/AddMasterDistributor')),
);

const Employee = Loadable(lazy(() => import('../views/Admin/UserManagement/Employee/Employee')));
const AddEmployee = Loadable(
  lazy(() => import('../views/Admin/UserManagement/Employee/AddEmployee')),
);
const UserRequest = Loadable(lazy(()=> import('../views/Admin/UserManagement/UserRequest/UserRequest')))
const UserRequestDetails = Loadable(lazy(()=> import('../views/Admin/UserManagement/UserRequest/UserRequestDetails')))
const SetUserKycAmount = Loadable(lazy(()=> import('../views/Admin/UserManagement/KYC/SetUserKycAmount')))
const AddDocument = Loadable(lazy(() => import('../views/Admin/UserManagement/AddDocument')));
const UpdateKYC = Loadable(lazy(() => import('../views/Admin/UserManagement/UpdateKYC')));
// Balance Request
const ViewBalanceRequestAdmin = Loadable(
  lazy(() => import('../views/Admin/BalanceRequest/ViewBalanceRequestAdmin')),
);
const ViewPendingRequestAdmin = Loadable(
  lazy(() => import('../views/Admin/BalanceRequest/ViewPendingRequestAdmin')),
);

/*********Commission Module **********/
const GetCommissionPlanMaster = Loadable(
  lazy(() =>
    import('../views/Admin/CommissionModule/CommissionPlanMaster/GetCommissionPlanMaster'),
  ),
);
const CreateCommissionPlanMaster = Loadable(
  lazy(() =>
    import('../views/Admin/CommissionModule/CommissionPlanMaster/CreateCommssionPlanMaster'),
  ),
);
const CreateCommissionMasterTemplateAdmin = Loadable(
  lazy(() => import('../views/Admin/CommissionModule/CommissionPlanMaster/CreateCommissionMasterTemplateAdmin')),
);
const AllPlans = Loadable(
  lazy(() => import('../views/Admin/CommissionModule/PlanMaster/AllPlans')),
);
const AddPlan = Loadable(lazy(() => import('../views/Admin/CommissionModule/PlanMaster/AddPlan')));

const AddServiceCategory = Loadable(
  lazy(() => import('../views/Admin/CommissionModule/ServiceCategoryMaster/AddServiceCategory')),
);
const AllServiceCategory = Loadable(
  lazy(() => import('../views/Admin/CommissionModule/ServiceCategoryMaster/AllServiceCategory')),
);

/*** Manage credit Detaials */
const CreditDebitUsers = Loadable(
  lazy(() => import('../views/Admin/ManageCreditDetails/CreditDebitUsers')),
);
const CreditDebitDetails = Loadable(
  lazy(() => import('../views/Admin/ManageCreditDetails/CreditDebitDetails')),
);
const CreditSelfAdmin = Loadable(
  lazy(() => import('../views/Admin/ManageCreditDetails/CreditSelfAdmin')),
);
/*** Manage Bank */

const AdminSelfBank = Loadable(
  lazy(() => import('../views/Admin/ManageBank/SelfBank/AdminSelfBank')),
);
const AdminSelfBankAdd = Loadable(
  lazy(() => import('../views/Admin/ManageBank/SelfBank/AdminSelfBankAdd')),
);
const AdminSelfbankUpdate = Loadable(
  lazy(() => import('../views/Admin/ManageBank/SelfBank/AdminSelfbankUpdate')),
);
const MainBankList = Loadable(
  lazy(() => import('../views/Admin/ManageBank/ManageMainBank/MainBankList')),
);
const AddMainBank = Loadable(
  lazy(() => import('../views/Admin/ManageBank/ManageMainBank/AddMainBank')),
);
const BankDataManager = Loadable(
  lazy(() => import('../views/Admin/ManageBank/BankDataManager')),
);
const BankDataAddManager = Loadable(
  lazy(() => import('../views/Admin/ManageBank/BankDataAddManager')),
);
const PaymentOperatorList = Loadable(
  lazy(() => import('../views/Admin/ManageBank/PaymentOperator/PaymentOperatorList')),
);
// Management
const AppLoginPin = Loadable(lazy(() => import('../views/Admin/Management/AppLoginPin')));
const ChangeMobileNo = Loadable(lazy(() => import('../views/Admin/Management/AppLoginPin')));
const ComplaintManager = Loadable(lazy(() => import('../views/Admin/Management/ComplaintManager')));
const ChangeTpinAdmin= Loadable(lazy(() => import('../views/Admin/Management/ChangeTpin')));// Admin Rports

const CustomerLimitInc = Loadable(lazy(() => import('../views/Admin/Management/CustomerLimitInc')));
const CustomerNameUpdate = Loadable(lazy(() => import('../views/Admin/Management/CustomerNameUpdate')));
const EnrollmentList = Loadable(lazy(() => import('../views/Admin/Management/EnrollmentList')));
const LoginHistory = Loadable(lazy(() => import('../views/Admin/Management/LoginHistory')));
const Notification = Loadable(lazy(() => import('../views/Admin/Management/Notification')));
const NotificationAdd = Loadable(lazy(() => import('../views/Admin/Management/NotificationAdd')));
const AddBanner= Loadable(lazy(() => import('../views/Admin/Management/AddBanner'))); 
const ServicesControl = Loadable(lazy(() => import('../views/Admin/Management/ServicesControl')));
const UserManagement = Loadable(lazy(() => import('../views/Admin/Management/UserManagement')));
const SystemSetting = Loadable(lazy(() => import('../views/Admin/Management/SystemSetting')));
const BusinessRights = Loadable(lazy(() => import('../views/Admin/Management/BusinessRights')));
// Admin Rports
const AdminChangeStatus = Loadable(lazy(() => import('../views/Admin/Reports/AdminChangeStatus')));
const AdminLedgeReports = Loadable(lazy(() => import('../views/Admin/Reports/AdminLedgerReports')));
const AdminLedgeReconsileReports = Loadable(lazy(() => import('../views/Admin/Reports/AdminLedgeReconsileReports')));
const PayOutTransactionReports = Loadable(lazy(() => import('../views/Admin/Reports/PayOutTransactionReports')));
const AdminSelfLedger= Loadable(lazy(() => import('../views/Admin/Reports/AdminSelfLedger')));
const CommissionReports = Loadable(lazy(() => import('../views/Admin/Reports/CommissionReports')));
const AdminTransactionReport = Loadable(
  lazy(() => import('../views/Admin/Reports/AdminTransactionReport')),
);
const GetDMTTransactionReports = Loadable(
  lazy(() => import('../views/Admin/Reports/DMTTransactionReports')),
);
const MoneyTransferHoldCasesAdmin = Loadable(lazy(()=> import('../views/Admin/Reports/MoneyTransferHoldCasesAdmin')))
const DaileyTrasactionReports = Loadable(
  lazy(() => import('../views/Admin/Reports/DaileyTrasactionReports')),
);
const AdminBillPaymentReports = Loadable(lazy(()=> import('../views/Admin/Reports/AdminBillPaymentReports')))
const KycPaymentsStatus = Loadable(lazy(()=> import('../views/Admin/Reports/KycPaymentsStatus')))
const AEPSTransactionReportsAdmin = Loadable(lazy(()=> import('../views/Admin/Reports/AEPSTransactionReportsAdmin')))
// Admin Report New pages add 26-06-2024
const AdminCheckStatusReportByExcel = Loadable(lazy(() => import('../views/Admin/Reports/AccountReport/CheckStatusReportByExcel')));
const AdminRechargeReport = Loadable(lazy(()=> import('../views/Admin/Reports/AdminRechargeReport')))
const OutStandingReportsAdmin = Loadable(lazy(()=> import('../views/Admin/Reports/OutStandingReportsAdmin')))
// 01-7-2024 Ayush
const AdminRechargeTransactions = Loadable(
  lazy(() => import("../views/Admin/Reports/AdminRechargeTransactions"))
);
const AdminRechargePendingTransactions = Loadable(
  lazy(() => import("../views/Admin/Reports/AdminRechargePendingTransactions"))
);
const AdminRechargeRefundCases = Loadable(
  lazy(() => import("../views/Admin/Reports/AdminRechargeRefundCases"))
);
const AdminMoneyTransferHoldCases = Loadable(lazy(()=> import('../views/Admin/Reports/AdminMoneyTransferHoldCases')))
// Admin Account Report New pages add 26-06-2024
const AdminAccountReport = Loadable(lazy(()=> import('../views/Admin/Reports/AccountReport/AdminAccountReport')))
const AdminWalletReport = Loadable(lazy(()=> import('../views/Admin/Reports/AccountReport/AdminWalletReport')))
const AdminFullDayReport = Loadable(lazy(()=> import('../views/Admin/Reports/AccountReport/AdminFullDayReport')))
const AdminBusinessReport = Loadable(lazy(()=> import('../views/Admin/Reports/AccountReport/AdminBusinessReport')))
const AdminBusinessReports = Loadable(lazy(()=> import('../views/Admin/Reports/AccountReport/AdminBusinessReports')))
const AdminICICBankAccontStatStatement = Loadable(lazy(()=> import('../views/Admin/Reports/AccountReport/AdminICICBankAccontStatStatement')))
const AdminTDSReportPancardWise = Loadable(lazy(()=> import('../views/Admin/Reports/AccountReport/AdminTDSReportPancardWise')))
const AdminKYCPendingReport = Loadable(lazy(()=> import('../views/Admin/Reports/AccountReport/AdminKYCPendingReport')))
const AdminKYCApproveReport = Loadable(lazy(()=> import('../views/Admin/Reports/AccountReport/AdminKYCApproveReport')))
const AdminUserReport = Loadable(lazy(()=> import('../views/Admin/Reports/AccountReport/AdminUserReport')))
const AdminPayoutTransactionBatch = Loadable(lazy(()=> import('../views/Admin/Reports/AccountReport/AdminPayoutTransactionBatch')))
const AdminUserList = Loadable(lazy(()=> import('../views/Admin/Reports/AccountReport/AdminUserList')))
const AdminCashOutReport = Loadable(lazy(()=> import('../views/Admin/Reports/AccountReport/AdminCashOutReport')))
const AdminTopUpReport = Loadable(lazy(()=> import('../views/Admin/Reports/AdminTopUpReport')))
const AdminDailyBusinessReports = Loadable(lazy(()=> import('../views/Admin/Reports/AccountReport/AdminDailyBusinessReports')))
const AccountStatusReports = Loadable(lazy(()=> import('../views/Admin/Reports/AccountReport/AccountStatusReports')))
const AdminBalanceAnalysisReport = Loadable(lazy(()=> import('../views/Admin/Reports/AccountReport/AdminBalanceAnalysisReport')))
const AdminDMTLogReport = Loadable(lazy(()=> import('../views/Admin/Reports/AccountReport/AdminDMTLogReport')))
const AdminVirificationChargeReport = Loadable(lazy(()=> import('../views/Admin/Reports/AccountReport/AdminVirificationChargeReport')))


/***** Super Distributor****/
const SuperDistributorDashboard = Loadable(
  lazy(() => import('../views/Dashboards/SuperDistributorDashboard')),
);
const SuperDocumentKyc = Loadable(lazy(() => import('../views/SuperDistributor/AddDocumentKyc')));

/***** DistributorPages ****/
const DistributorDashboard = Loadable(
  lazy(() => import('../views/Dashboards/DistributorDashboard')),
);

const DistributorDocsKyc = Loadable(lazy(() => import('../views/Distributor/AddDocumentKyc')));

const CreateRetailerDistributor = Loadable(
  lazy(() => import('../views/Distributor/ManageRetailer/CreateRetailerDistributor')),
);

const ViewRetailerDistributor = Loadable(
  lazy(() => import('../views/Distributor/ManageRetailer/ViewRetailerDistributor')),
);

const RetailerDocs = Loadable(
  lazy(() => import('../views/Distributor/ManageRetailer/RetailerDocs')),
);

const ManageCreditDebitDistributor = Loadable(
  lazy(() => import('../views/Distributor/ManageCreditDebit/ManageCreditDebitDistributor')),
);

const CreditAmountToRetailer = Loadable(
  lazy(() => import('../views/Distributor/ManageCreditDebit/CreditAmountToRetailer')),
);

const CreditDetailsDistributor = Loadable(
  lazy(() => import('../views/Distributor/CreditDetail/CreditDetailsDistributor')),
);

const LedgerReportDistributor = Loadable(
  lazy(() => import('../views/Distributor/Reports/LedgerReportDistributor')),
);

const DistributorBusinessReport = Loadable(
  lazy(() => import('../views/Distributor/Reports/DistributorBusinessReport')),
);

const DistributorOutStandingReports = Loadable(
  lazy(() => import('../views/Distributor/Reports/DistributorOutStandingReports')),
);
const DirectTopUpReports = Loadable(
  lazy(() => import('../views/Distributor/Reports/DirectTopUpReports')),
);
// Balance Request
const BalanceRequestHistoryDistributor = Loadable(
  lazy(() => import('../views/Distributor/BalanceRequest/BalanceRequestHistory')),
);
const NewBalanceRequestDistributor = Loadable(
  lazy(() => import('../views/Distributor/BalanceRequest/NewBalanceRequest')),
);
const AddNewBankDistributor = Loadable(
  lazy(() => import('../views/Distributor/SelfBank/AddNewBank')),
);
const DistributorBankList = Loadable(
  lazy(() => import('../views/Distributor/SelfBank/DistributorBankList')),
);
const UpdateDistributorBank = Loadable(
  lazy(() => import('../views/Distributor/SelfBank/UpdateDistributorBank')),
);
const ChangePasswordDistributer = Loadable(
  lazy(() => import('../views/Distributor/Profile/ChangePassword')),
);
const ProfileDetails= Loadable(
  lazy(() => import('../views/Distributor/ManageRetailer/ProfileDetails')));
// profile
const UpdateProfileDistributor = Loadable(
  lazy(() => import('../views/Distributor/Profile/UpdateProfileDistributor')),
);
/***** Super Distributor****/
// const SuperDistributorDashboard = Loadable(
//   lazy(() => import('../views/Dashboards/SuperDistributorDashboard')),
// );

const SuperViewRetailerDistributor = Loadable(
  lazy(() => import('../views/SuperDistributor/ManageDistributor/SuperViewRetailerDistributor')),
);

const SuperCreateRetailerDistributor = Loadable(
  lazy(() => import('../views/SuperDistributor/ManageDistributor/SuperCreateRetailerDistributor')),
);

const DistributorDocs = Loadable(
  lazy(() => import('../views/SuperDistributor/ManageDistributor/DistributorDocs')),
);

const SuperManageCreditDebitDistributor = Loadable(
  lazy(() =>
    import('../views/SuperDistributor/ManageCreditDebit/SuperManageCreditDebitDistributor'),
  ),
);

const SuperCreditAmountToRetailer = Loadable(
  lazy(() => import('../views/SuperDistributor/ManageCreditDebit/SuperCreditAmountToRetailer')),
);

const MasterManageCreditDebitDistributor = Loadable(
  lazy(() =>
    import('../views/MasterDistributor/ManageCreditDebit/MasterManageCreditDebitDistributor'),
  ),
);

const MasterCreditAmountToRetailer = Loadable(
  lazy(() => import('../views/MasterDistributor/ManageCreditDebit/MasterCreditAmountToRetailer')),
);

const SuperCreditDetailsDistributor = Loadable(
  lazy(() => import('../views/SuperDistributor/CreditDetail/SuperCreditDetailsDistributor')),
);

const CommisionSuperReportDistributor = Loadable(
  lazy(() => import('../views/SuperDistributor/Reports/CommisionSuperReportDistributor')),
);

const LedgerMasterReportDistributor = Loadable(
  lazy(() => import('../views/MasterDistributor/Reports/LedgerMasterReportDistributor')),
);

const LedgerSuperReportDistributor = Loadable(
  lazy(() => import('../views/SuperDistributor/Reports/LedgerSuperReportDistributor')),
);

const SuperDistributorOutStandingReports = Loadable(
  lazy(() => import('../views/SuperDistributor/Reports/SuperDistributorOutStandingReports')),
);
const DirectTopUpReport = Loadable(
  lazy(() => import('../views/SuperDistributor/Reports/DirectTopUpReports')),
);
const SuperDistributorBusinessReport = Loadable(
  lazy(() => import('../views/SuperDistributor/Reports/SuperDistributorBusinessReport')),
);

// Profile
const UpdateProfileSD = Loadable(
  lazy(() => import('../views/SuperDistributor/Profile/ProfileUpdateSD')),
);
const ChangePasswordSP = Loadable(
  lazy(() => import('../views/SuperDistributor/Profile/ChangePasswordSP')),
);

// SELF BANK
const AddNewBankSuperDistributor = Loadable(
  lazy(() => import('../views/SuperDistributor/SelfBank/AddNewBank')),
);
const SPDistributorBankList = Loadable(
  lazy(() => import('../views/SuperDistributor/SelfBank/SPDistributorBankList')),
);
const UpdateSPDistributorBank = Loadable(
  lazy(() => import('../views/SuperDistributor/SelfBank/UpdateDistributorBank')),
);

// BALANCE REQUEST
const BalanceRequestHistorySuper = Loadable(
  lazy(() => import('../views/SuperDistributor/BalanceRequest/BalanceRequestHistory')),
);
const NewBalanceRequestSuper = Loadable(
  lazy(() => import('../views/SuperDistributor/BalanceRequest/NewBalanceRequest')),
);

// const ChangePasswordDistributor = Loadable(lazy(() => import('../views/Retailer/User/ChangePassword')));
/***** RetailerPages ****/
const RetailerDashboard = Loadable(lazy(() => import('../views/Dashboards/RetailerDashboard')));

/****Banking Services */
const DMTDashboard = Loadable(
  lazy(() => import('../views/Retailer/BankingServices/DMT/DMTDashboard')),
);
const SearchCustomer = Loadable(
  lazy(() => import('../views/Retailer/BankingServices/DMT/SearchCustomer')),
);
const AddCustomer = Loadable(
  lazy(() => import('../views/Retailer/BankingServices/DMT/CustomerAdd')),
);
const BenificiaryList = Loadable(
  lazy(() => import('../views/Retailer/BankingServices/DMT/BenificiaryList')),
);
const AddBenificiary = Loadable(
  lazy(() => import('../views/Retailer/BankingServices/DMT/BenificiaryAdd')),
);
const BenificiaryDetails = Loadable(
  lazy(() => import('../views/Retailer/BankingServices/DMT/BenificiaryDetails')),
);
const TransactionReciept = Loadable(
  lazy(() => import('../views/Retailer/BankingServices/DMT/TransactionReciept')),
);

// PAYOUT-1
const SearchPayoutCustomer = Loadable(lazy(()=> import('../views/Retailer/BankingServices/Payout/SearchPayoutCustomer')))
const AddCustomerPayout = Loadable(lazy(()=> import('../views/Retailer/BankingServices/Payout/AddCustomerPayout')))
const PayAddBeneficiary = Loadable(lazy(()=> import('../views/Retailer/BankingServices/Payout/PayAddBeneficiary')))
const PayoutBeneficiaryList = Loadable(lazy(()=> import('../views/Retailer/BankingServices/Payout/PayoutBeneficiaryList')))
const PayOutBeniDetails = Loadable(lazy(()=> import('../views/Retailer/BankingServices/Payout/PayOutBeniDetails')))

/****Self Bank ****/
const RetailorBankList = Loadable(
  lazy(() => import('../views/Retailer/SelfBank/RetailorBankList')),
);

const AddNewBank = Loadable(lazy(() => import('../views/Retailer/SelfBank/AddNewBank')));
const UpdateRetailorBank = Loadable(
  lazy(() => import('../views/Retailer/SelfBank/UpdateRetailorBank')),
);

// Balance Request
const BalanceRequestHistory = Loadable(
  lazy(() => import('../views/Retailer/BalanceRequest/BalanceRequestHistory')),
);
const NewBalanceRequest = Loadable(
  lazy(() => import('../views/Retailer/BalanceRequest/NewBalanceRequest')),
);
const ComplaintAddAgent = Loadable(
  lazy(() => import('../views/Retailer/Profile/ComplaintAddAgent')),
);
const ComplaintListAgent = Loadable(
  lazy(() => import('../views/Retailer/Profile/ComplaintListAgent')),
);

/****Retailor Reports */
const AllRetailorReports = Loadable(
  lazy(() => import('../views/Retailer/Reports/AllRetailorReports')),
);
const AgentAccountStatusReports = Loadable(
  lazy(() => import('../views/Retailer/Reports/AgentAccountStatusReports')),
);
// Profile
const UpdateProfileAgent = Loadable(
  lazy(() => import('../views/Retailer/Profile/UpdateProfileAgent')),
);
const SetTPIN = Loadable(lazy(() => import('../views/Retailer/Profile/SetTPIN')));
const ResetTPIN = Loadable(lazy(() => import('../views/Retailer/Profile/ResetTPIN')));
const ChangePassword = Loadable(lazy(() => import('../views/Retailer/Profile/ChangePassword')));
/***** BusinessPages ****/
const BusinessPartnerDashboard = Loadable(
  lazy(() => import('../views/Dashboards/BusinessPartnerDashboard')),
);
//Balance Request
const BalanceRequestHistoryBusiness = Loadable(
  lazy(() => import('../views/Business/BalanceRequest/BalanceRequestHistory')),
);
const CreateBalanceRequest = Loadable(
  lazy(() => import('../views/Business/BalanceRequest/CreateBalanceRequest')),
);
// self bank
const BusinessBankList = Loadable(
  lazy(() => import('../views/Business/SelfBank/BusinessBankList')),
);
const AddBusinessBank = Loadable(lazy(() => import('../views/Business/SelfBank/AddBusinessBank')));
const UpdateBusinessBank = Loadable(
  lazy(() => import('../views/Business/SelfBank/UpdateBusinessBank')),
);
const UpdateProfileBusiness = Loadable(lazy(()=> import('../views/Business/Profile/UpdateProfileBusiness')))
const ChangePasswordBD = Loadable(lazy(()=> import('../views/Business/Profile/ChangePasswordBD')))
/***** MasterPages ****/
const MasterDistributorDashboard = Loadable(
  lazy(() => import('../views/Dashboards/MasterDistributorDashboard')),
);
const MasterDocumentKyc = Loadable(lazy(() => import('../views/MasterDistributor/AddDocumentKyc')));
// Balance Request
const BalanceRequestHistoryMaster = Loadable(
  lazy(() => import('../views/MasterDistributor/BalanceRequest/BalanceRequestHistoryMaster')),
);
const NewBalanceRequestMaster = Loadable(
  lazy(() => import('../views/MasterDistributor/BalanceRequest/NewBalanceRequestMaster')),
);
// credit Details
const MasterCreditDetails = Loadable(lazy(()=> import('../views/MasterDistributor/CreditDetail/MasterCreditDetails')))
// self Bank
const MasterBankList = Loadable(
  lazy(() => import('../views/MasterDistributor/SelfBank/MasterBankList')),
);
const AddMasterBank = Loadable(
  lazy(() => import('../views/MasterDistributor/SelfBank/AddMasterBank')),
);
const UpdateMasterBank = Loadable(
  lazy(() => import('../views/MasterDistributor/SelfBank/UpdateMasterBank')),
);

// manage SuperDistributor

const MasterCreateSuperDistributor = Loadable(
  lazy(() => import('../views/MasterDistributor/ManageDistributor/MasterCreateSuperDistributor')),
);
const MasterViewSuperDistributor = Loadable(
  lazy(() => import('../views/MasterDistributor/ManageDistributor/MasterViewSuperDistributor')),
);
const SuperDistributorDocs = Loadable(
  lazy(() => import('../views/MasterDistributor/ManageDistributor/SuperDistributorDocs')),
);

/***** EmployePages ****/
const EmployeeDashboard = Loadable(lazy(() => import('../views/Dashboards/EmployeeDashboard')));

// Balance Request
const NewBalanceRequestEmployee = Loadable(
  lazy(() => import('../views/Employe/BalanceRequest/NewBalanceRequestEmployee')),
);
const BalanceRequestHistoryEmployee = Loadable(
  lazy(() => import('../views/Employe/BalanceRequest/BalanceRequestHistoryEmployee')),
);

// Self Bank
const EmployeeBankList = Loadable(lazy(() => import('../views/Employe/SelfBank/EmployeeBankList')));
const AddEmployeeBank = Loadable(lazy(() => import('../views/Employe/SelfBank/AddEmployeeBank')));
const UpdateEmployeeBank = Loadable(
  lazy(() => import('../views/Employe/SelfBank/UpdateEmployeeBank')),
);
const ChangePasswordEMP = Loadable(lazy(() => import('../views/Employe/Profile/ChangePasswordEMP')));
const ChangePasswordMd = Loadable(
  lazy(() => import('../views/MasterDistributor/Profile/ChangePasswordMD')),
);
const UpdateProfileMD = Loadable(lazy(()=>  import('../views/MasterDistributor/Profile/ProfileUpdateMD')))
/***** Pages ****/

/*** Recharge ****/
const DTHRecharge = Loadable(lazy(() => import('../views/Retailer/Recharge/DTHRecharge')));
const MobileRecharge = Loadable(lazy(() => import('../views/Retailer/Recharge/MobileRecharge')));
const AddDocumentKyc = Loadable(lazy(() => import('../views/Retailer/AddDocumentKyc')));
const PayementReciept = Loadable(lazy(() => import('../views/Retailer/Recharge/PayementReciept')));
// BILL PAYMENTS
const BillPaymentsBa = Loadable(lazy(() => import('../views/Retailer/BillPayments/BillAvenue/BillPayments')));
 
/****AEPS *****/
const AepsSearch = Loadable(lazy(() => import('../views/Retailer/AEPS/AEPSIservue/AepsSearch')));
const AEPSOnboarding = Loadable(
  lazy(() => import('../views/Retailer/AEPS/AEPSIservue/AEPSOnboarding')),
);
const AEPSCaptureIs  = Loadable(lazy(() => import('../views/Retailer/AEPS/AEPSIservue/AEPSCaptureIs')));
const OnboardingUser = Loadable(lazy(() => import('../views/Retailer/AEPS/AEPSPs/OnboardingUser')));
const AEPSSearchPs =Loadable(lazy(() => import('../views/Retailer/AEPS/AEPSPs/AEPSSearchPs')));
const AEPSDailyAuthantication =Loadable(lazy(() => import('../views/Retailer/AEPS/AEPSPs/AEPSDailyAuthantication')));
const AEPSRegistrationUser =Loadable(lazy(() => import('../views/Retailer/AEPS/AEPSPs/AEPSRegistrationUser')));
const AEPSWithdralCapture = Loadable(lazy(() => import('../views/Retailer/AEPS/AEPSPs/AEPSWithdralCapture')));
const AEPSBalAndMinistatment = Loadable(lazy(() => import('../views/Retailer/AEPS/AEPSPs/AEPSBalAndMinistatment')));
const AEPSTransactionSelect = Loadable(lazy(() => import('../views/Retailer/AEPS/AEPSPs/AEPSTransactionSelect')));
const AEPSTransactionReciept = Loadable(lazy(() => import('../views/Retailer/AEPS/AEPSTransactionReciept')));
const AEPSToDMTTransfer = Loadable(lazy(() => import('../views/Retailer/AEPS/AEPSToDMTTransfer')));
const AEPSPayout = Loadable(lazy(() => import('../views/Retailer/AEPS/AEPSPayout')));
const AEPSPayoutBeneficiaryDetails = Loadable(lazy(() => import('../views/Retailer/AEPS/AEPSPayoutBeneficiaryDetails')));
// const AEPSTransactionReports = Loadable(lazy(() => import('../views/Retailer/')));

// AEPS EKO

const AEPSSearchEk = Loadable(lazy(() => import('../views/Retailer/AEPS/AEPSEko/AEPSSearchEk')));
const OnboardingUserEko = Loadable(lazy(() => import('../views/Retailer/AEPS/AEPSEko/OnboardingUser')));
const ServiceActivationEko = Loadable(lazy(() => import('../views/Retailer/AEPS/AEPSEko/ServiceActivation')));
const AepsRegistration = Loadable(lazy(() => import('../views/Retailer/AEPS/AEPSEko/AepsRegistration')));
const AepsDailyAuthantication = Loadable(lazy(() => import('../views/Retailer/AEPS/AEPSEko/AepsDailyAuthantication')));

// REPORTS
const LedgerReport = Loadable(lazy(() => import('../views/Retailer/Reports/LedgerReport')));
const AEPSLedgerReport = Loadable(lazy(() => import('../views/Retailer/Reports/AEPSLedgerReport')));
const DMTTransactionReports = Loadable(lazy(() => import('../views/Retailer/Reports/DMTTransactionReports')));
const MoneyTransferHoldCases = Loadable(lazy(() => import('../views/Retailer/Reports/MoneyTransferHoldCases')));
const GetTransactionByAccountNumber = Loadable(lazy(() => import('../views/Retailer/Reports/GetTransactionByAccountNumber')));
const GetTransactionByConsumerNo = Loadable(lazy(() => import('../views/Retailer/Reports/GetTransactionByConsumerNo')));
const AEPSTransactionReports = Loadable(lazy(() => import('../views/Retailer/Reports/AEPSTransactionReports')));
const TDSReports = Loadable(lazy(() => import('../views/Retailer/Reports/GetTDSTransactionReports')));
const GSTReports = Loadable(lazy(() => import('../views/Retailer/Reports/GetGSTTransactionReports')));
const TopUpReports = Loadable(lazy(() => import('../views/Retailer/Reports/TopUpReport')));
const OutStandingReports = Loadable(lazy(() => import('../views/Retailer/Reports/OutStandingReports')));
const PaymentGetwayReports = Loadable(lazy(() => import('../views/Retailer/Reports/PaymentGetwayReports')));
const BBPSPaymentsReports = Loadable(lazy(() => import('../views/Retailer/Reports/RechargeReport')));
// PG
const PaymentGetway = Loadable(lazy(() => import('../views/Retailer/PaymentGatway/PaymentGetway')));

/***** Auth Pages ****/
const Home = Loadable(lazy(() => import('../views/Web/Home')));
const About = Loadable(lazy(() => import('../views/Web/AboutUs')));
const Services = Loadable(lazy(() => import('../views/Web/Services')));
const Contact = Loadable(lazy(() => import('../views/Web/Contact')));
const OurPartner = Loadable(lazy(() => import('../views/Web/OurPartner')));
// const BusinessWithUs = Loadable(lazy(() => import('../views/Web/BusinessWithUs')));
const PrivacyPolicy = Loadable(lazy(() => import('../views/Web/PrivacyPolicy')));
const RefundPolicy = Loadable(lazy(() => import('../views/Web/RefundPolicy')));
// const Career = Loadable(lazy(() => import('../views/Web/Career')));
const TermsAndCondition = Loadable(lazy(() => import('../views/Web/TermsAndCondition')));
const Error = Loadable(lazy(() => import('../views/Web/Error')));
const Login = Loadable(lazy(() => import('../views/Web/Login')));
const ForgotPassword = Loadable(lazy(() => import('../views/Web/ForgotPassword')));
const Maintanance = Loadable(lazy(() => import('../views/Web/Maintanance')));
const LockScreen = Loadable(lazy(() => import('../views/Web/LockScreen')));
const OtpVerification = Loadable(lazy(() => import('../views/Web/OtpVerification')));
const Signup = Loadable(lazy(() => import('../views/Web/Signup')));
const BBPS = Loadable(lazy(() => import('../views/Web/Bbps')));
const DevDoc = Loadable(lazy(() => import('../views/Web/DevelopersDocument')));
/*****Routes******/

const ThemeRoutes = [
  {
    path: '/',
    element: <BlankLayout />,
    children: [
      { path: '404', element: <Error /> },
      { path: '*', element: <Navigate to="/404" /> },
      { path: '/', name: 'home', element: <Home /> },
      { path: 'about', element: <About /> },
      { path: 'services', element: <Services /> },
      { path: 'contact', element: <Contact /> },
      { path: 'OurPartner', element: <OurPartner /> },
      // { path: 'PartnerWithUs', element: <BusinessWithUs /> },
      { path: 'privacypolicy', element: <PrivacyPolicy /> },
      { path: 'refundpolicy', element: <RefundPolicy /> },
      { path: 'termsandcondition', element: <TermsAndCondition /> },
      { path: 'Login', element: <Login /> },
      { path: 'ForgotPassword', element: <ForgotPassword /> },
      { path: 'OtpVerification', element: <OtpVerification /> },
      { path: 'maintanance', element: <Maintanance /> },
      { path: 'lockscreen', element: <LockScreen /> },
      // { path: 'Career', element: <Career /> },
      { path: 'signup', element: <Signup /> },
      { path: 'bbps', element: <BBPS />},
      { path: "developers_Doc", element: <DevDoc /> }
    ],
  },
  // AADMIN SCREEN
  {
    path: '/',
    element: <ProtectRoute Component={AdminLayout} />,
    children: [
      {
        path: '/Admin/AdminChangeStatus',
        name: 'AdminChangeStatus',
        exact: true,
        element: <AdminChangeStatus />,
      },
      {
        path: '/Profile/ProfileUpdate',
        name: 'ProfileUpdate',
        exact: true,
        element: <ProfileUpdate />,
      },
      {
        path: '/Admin/ChangesPasswordAd',
        name: 'ProfileUpdate',
        exact: true,
        element: <ChangesPasswordAd />,
      },
      {
        path: '/dashboards/admindashboard',
        name: 'admin',
        exact: true,
        element: <AdminDashboard />,
      },
      {
        path: '/UserManagement/SuperDistributor',
        name: 'SuperDistributor',
        exact: true,
        element: <SuperDistributor />,
      },
      {
        path: '/UserManagement/AddSuperDistributor',
        name: 'AddSuperDistributor',
        exact: true,
        element: <AddSuperDistributor />,
      },
      {
        path: '/Admin/MappingSuperDistributor',
        name: 'MappingSuperDistributor',
        exact: true,
        element: <MappingSuperDistributor />,
      },
      {
        path: '/Distributor/Distributor',
        name: 'Distributor',
        exact: true,
        element: <Distributor />,
      },
      {
        path: '/Distributor/AddDistributor',
        name: 'Distributor',
        exact: true,
        element: <AddDistributor />,
      },
      {
        path: '/Distributor/MappingDistributor',
        name: 'Distributor',
        exact: true,
        element: <MappingDistributor />,
      },
      {
        path: '/Retailer/AddRetailer',
        name: 'Retailer',
        exact: true,
        element: <AddRetailer />,
      },
      {
        path: '/Admin/AdminAPIUserMaster',
        name: 'Admin',
        exact: true,
        element: <AdminAPIUserMaster />,
      },
      {
        path: '/Admin/PendingKycVerification',
        name: 'Retailer',
        exact: true,
        element: <PendingKycVerification />,
      },
      {
        path: '/Admin/UserServiceStatus',
        name: 'Retailer',
        exact: true,
        element: <UserServiceStatus />,
      },
      
      {
        path: '/Retailer/Retailer',
        name: 'Retailer',
        exact: true,
        element: <Retailer />,
      },
      {
        path: '/Retailer/MappingRetailer',
        name: 'Retailer',
        exact: true,
        element: <MappingRetailer />,
      },
      {
        path: '/Admin/AddDocument',
        name: 'Retailer',
        exact: true,
        element: <AddDocument />,
      },
      {
        path: '/Admin/UpdateKYC',
        name: 'Retailer',
        exact: true,
        element: <UpdateKYC />,
      },
      {
        path: '/Admin/UpdateProfileMenu',
        name: 'Retailer',
        exact: true,
        element: <UpdateProfileMenu />,
      },
      {
        path: '/Role/AllRole',
        name: 'Role',
        exact: true,
        element: <AllRole />,
      },
      {
        path: '/Role/AddNewRole',
        name: 'Role',
        exact: true,
        element: <AddNewRole />,
      },
      {
        path: '/UseManagement/BusinessPartner',
        name: 'Business',
        exact: true,
        element: <BusinessPartner />,
      },
      {
        path: '/UseManagement/AddBusinessPartner',
        name: 'Business',
        exact: true,
        element: <AddBusiness />,
      },
      {
        path: '/UseManagement/MasterDistributor',
        name: 'MasterDistributor',
        exact: true,
        element: <MasterDistributor />,
      },
      {
        path: '/UseManagement/AddMasterDistributor',
        name: 'AddMasterDistributor',
        exact: true,
        element: <AddMasterDistributor />,
      },
      {
        path: '/UseManagement/Employee',
        name: 'Employee',
        exact: true,
        element: <Employee />,
      },
      {
        path: '/UseManagement/AddEmployee',
        name: 'AddEmployee',
        exact: true,
        element: <AddEmployee />,
      },
      {
        path: '/Admin/UserRequest',
        name: 'AddEmployee',
        exact: true,
        element: <UserRequest />,
      },
      {
        path: '/Admin/UserRequestDetails',
        name: 'AddEmployee',
        exact: true,
        element: <UserRequestDetails />,
      },
      {
        path: '/Admin/SetUserKycAmount',
        name: 'AddEmployee',
        exact: true,
        element: <SetUserKycAmount />,
      },
      
      // Balance Request
      {
        path: '/Admin/ViewBalanceRequestAdmin',
        name: 'Admin',
        exact: true,
        element: <ViewBalanceRequestAdmin />,
      },
      {
        path: '/Admin/ViewPendingRequestAdmin',
        name: 'Admin',
        exact: true,
        element: <ViewPendingRequestAdmin />,
      },

      // Commission module CreateCommissionPlanMaster
      {
        path: '/Admin/GetCommissionPlanMaster',
        name: 'CommissionModule',
        exact: true,
        element: <GetCommissionPlanMaster />,
      },
      {
        path: '/Admin/CreateCommissionPlanMaster',
        name: 'CommissionModule',
        exact: true,
        element: <CreateCommissionPlanMaster />,
      },
      {
        path: '/Admin/CreateCommissionMasterTemplateAdmin',
        name: 'CommissionModule',
        exact: true,
        element: <CreateCommissionMasterTemplateAdmin />,
      },
      
      {
        path: '/Admin/AllPlans',
        name: 'PlanMaster',
        exact: true,
        element: <AllPlans />,
      },
      {
        path: '/Admin/AddPlan',
        name: 'PlanMaster',
        exact: true,
        element: <AddPlan />,
      },
      {
        path: '/ServiceCategoryMaster/AllServiceCategory',
        name: 'ServiceCategoryMaster',
        exact: true,
        element: <AllServiceCategory />,
      },
      {
        path: '/ServiceCategoryMaster/AddServiceCategory',
        name: 'ServiceCategoryMaster',
        exact: true,
        element: <AddServiceCategory />,
      },

      // Manage Credit Details
      {
        path: '/ManageCreditDetails/CreditDebitUsers',
        name: 'CreditDebit',
        exact: true,
        element: <CreditDebitUsers />,
      },
      {
        path: '/ManageCreditDetails/CreditDebitDetails',
        name: 'CreditDebit',
        exact: true,
        element: <CreditDebitDetails />,
      },
      {
        path: '/ManageCreditDetails/CreditSelfAdmin',
        name: 'CreditDebit',
        exact: true,
        element: <CreditSelfAdmin />,
      },
      
      // Manage Bank
      {
        path: '/Admin/AdminSelfBank',
        name: 'CreditDebit',
        exact: true,
        element: <AdminSelfBank />,
      },
      {
        path: '/Admin/AdminSelfBankAdd',
        name: 'CreditDebit',
        exact: true,
        element: <AdminSelfBankAdd />,
      },
      {
        path: '/SelfBank/AdminSelfbankUpdate',
        name: 'CreditDebit',
        exact: true,
        element: <AdminSelfbankUpdate />,
      },
      {
        path: '/Admin/MainBankList',
        name: 'CreditDebit',
        exact: true,
        element: <MainBankList />,
      },
      {
        path: '/Admin/AddMainBank',
        name: 'CreditDebit',
        exact: true,
        element: <AddMainBank />,
      },
      {
        path: '/Admin/BankDataManager',
        name: 'BANK',
        exact: true,
        element: <BankDataManager />,
      },
      {
        path: '/Admin/BankDataAddManager',
        name: 'BANK',
        exact: true,
        element: <BankDataAddManager />,
      },
      {
        path: '/Admin/PaymentOperatorList',
        name: 'PaymentOperator',
        exact: true,
        element: <PaymentOperatorList />,
      },
      // Management
      {
        path: '/Admin/AppLoginPin',
        name: 'AppLoginPin',
        exact: true,
        element: <AppLoginPin />,
      },
      {
        path: '/Admin/ChangeMobileNo',
        name: 'ChangeMobileNo',
        exact: true,
        element: <ChangeMobileNo />,
      },
      {
        path: '/Admin/ComplaintManager',
        name: 'ComplaintManager',
        exact: true,
        element: <ComplaintManager />,
      },
      {
        path: '/Admin/CustomerLimitInc',
        name: 'CustomerLimitInc',
        exact: true,
        element: <CustomerLimitInc />,
      },
      {
        path: '/Admin/CustomerNameUpdate',
        name: 'CustomerLimitInc',
        exact: true,
        element: <CustomerNameUpdate />,
      },
      
      {
        path: '/Admin/ChangeTpin',
        name: 'Tpin',
        exact: true,
        element: <ChangeTpinAdmin />,
      },
      {
        path: '/Admin/EnrollmentList',
        name: 'EnrollmentList',
        exact: true,
        element: <EnrollmentList />,
      },
      {
        path: '/Admin/LoginHistory',
        name: 'LoginHistory',
        exact: true,
        element: <LoginHistory />,
      },
      {
        path: '/Admin/Notification',
        name: 'Notification',
        exact: true,
        element: <Notification />,
      },
      {
        path: '/Admin/NotificationAdd',
        name: 'NotificationAdd',
        exact: true,
        element: <NotificationAdd />,
      },
      {
        path: '/Admin/AddBanner',
        name: 'banner',
        exact: true,
        element: <AddBanner />,
      },
      
      {
        path: '/Admin/BusinessRights',
        name: 'BusinessRights',
        exact: true,
        element: <BusinessRights />,
      },
      {
        path: '/Admin/ServicesControl',
        name: 'ServicesControl',
        exact: true,
        element: <ServicesControl />,
      },
      {
        path: '/Admin/UserManagement',
        name: 'UserManagement',
        exact: true,
        element: <UserManagement />,
      },
      {
        path: '/Admin/SystemSetting',
        name: 'SystemSetting',
        exact: true,
        element: <SystemSetting />,
      },
      
      // Reports
      {
        path: '/Admin/AdminLedgeReports',
        name: 'CreditDebit',
        exact: true,
        element: <AdminLedgeReports />,
      },
      {
        path: '/Admin/AdminLedgeReconsileReports',
        name: 'Reports',
        exact: true,
        element: <AdminLedgeReconsileReports />,
      },
      {
        path: '/Admin/PayOutTransactionReports',
        name: 'PayOutTransactionReports',
        exact: true,
        element: <PayOutTransactionReports />,
      },
      
      {
        path: '/Admin/AdminSelfLedger',
        name: 'CreditDebit',
        exact: true,
        element: <AdminSelfLedger />,
      },
      {
        path: '/Admin/CommissionReports',
        name: 'CreditDebit',
        exact: true,
        element: <CommissionReports />,
      },  
      
      {
        path: '/Reports/AdminTransactionReport',
        name: 'Reports',
        exact: true,
        element: <AdminTransactionReport />,
      },
      {
        path: '/Reports/GetDMTTransactionReports',
        name: 'Reports',
        exact: true,
        element: <GetDMTTransactionReports />,
      },
      {
        path: '/Admin/MoneyTransferHoldCaseAdmin',
        name: 'MoneyTransferHoldCaseAdmin',
        exact: true,
        element: <MoneyTransferHoldCasesAdmin />,
      },
      {
        path: '/Reports/DaileyTrasactionReports',
        name: 'Reports',
        exact: true,
        element: <DaileyTrasactionReports />,
      },
      
      {
        path: '/Reports/AdminBillPaymentReports',
        name: 'Reports',
        exact: true,
        element: <AdminBillPaymentReports />,
      },
      {
        path: '/Reports/KycPaymentsStatus',
        name: 'Reports',
        exact: true,
        element: <KycPaymentsStatus />,
      },
      {
        path: '/Reports/AEPSTransactionReportsAdmin',
        name: 'Reports',
        exact: true,
        element: <AEPSTransactionReportsAdmin />,
      },
      {
        path: '/Reports/OutStandingReportsAdmin',
        name: 'Reports',
        exact: true,
        element: <OutStandingReportsAdmin />,
      },
      // Admin Report Add new pagess
      {
        path: '/Reports/AdminRechargeReport',
        name: 'Reports',
        exact: true,
        element: <AdminRechargeReport/>,
      },
      // 01-07-2024 Ayush
      {
        path: "/Reports/AdminRechargeTransactions",
        name: "Reports",
        exact: true,
        element: <AdminRechargeTransactions />,
      },
      {
        path: "/Reports/AdminRechargePendingTransactions",
        name: "Reports",
        exact: true,
        element: <AdminRechargePendingTransactions />,
      },
      {
        path: "/Reports/AdminRechargeRefundCases",
        name: "Reports",
        exact: true,
        element: <AdminRechargeRefundCases />,
      },
      {
        path: '/Reports/AdminMoneyTransferHoldCases',
        name: 'Reports',
        exact: true,
        element: <AdminMoneyTransferHoldCases/>,
      },
      // Admin Account Report Add new pagess
      {
        path: '/Reports/AccountReport/AdminAccountReport',
        name: 'AccountReport',
        exact: true,
        element: <AdminAccountReport/>,
      },
      {
        path: '/Admin/AdminWalletReport',
        name: 'AccountReport',
        exact: true,
        element: <AdminWalletReport/>,
      },
      {
        path: '/Reports/AccountReport/AdminFullDayReport',
        name: 'AccountReport',
        exact: true,
        element: <AdminFullDayReport/>,
      },
      {
        path: '/Admin/AdminBusinessReport',
        name: 'AccountReport',
        exact: true,
        element: <AdminBusinessReport/>,
      },
      {
        path: '/Admin/AdminBusinessReports',
        name: 'AccountReport',
        exact: true,
        element: <AdminBusinessReports/>,
      },
      {
        path: '/Reports/AccountReport/AdminICICBankAccontStatStatement',
        name: 'AccountReport',
        exact: true,
        element: <AdminICICBankAccontStatStatement/>,
      },
      {
        path: '/Reports/AccountReport/AdminTDSReportPancardWise',
        name: 'AccountReport',
        exact: true,
        element: <AdminTDSReportPancardWise/>,
      },
      {
        path: '/Reports/AccountReport/AdminKYCPendingReport',
        name: 'AccountReport',
        exact: true,
        element: <AdminKYCPendingReport/>,
      },
      {
        path: '/Reports/AccountReport/AdminKYCApproveReport',
        name: 'AccountReport',
        exact: true,
        element: <AdminKYCApproveReport/>,
      },
      {
        path: '/Reports/AccountReport/AdminUserReport',
        name: 'AccountReport',
        exact: true,
        element: <AdminUserReport/>,
      },
      {
        path: '/Reports/GetPayoutTransactionBatch',
        name: 'AccountReport',
        exact: true,
        element: <AdminPayoutTransactionBatch/>,
      },
      {
        path: '/Reports/AccountReport/AdminUserList',
        name: 'AccountReport',
        exact: true,
        element: <AdminUserList/>,
      },
      {
        path: '/Reports/AccountReport/AdminCashOutReport',
        name: 'AccountReport',
        exact: true,
        element: <AdminCashOutReport/>,
      },
      {
        path: '/Reports/AdminTopUpReport',
        name: 'AccountReport',
        exact: true,
        element: <AdminTopUpReport/>,
      },
      {
        path: '/Reports/AdminDailyBusinessReports',
        name: 'AdminDailyBusinessReports',
        exact: true,
        element: <AdminDailyBusinessReports/>,
      },
      {
        path: '/Reports/AccountStatusReports',
        name: 'AccountReport',
        exact: true,
        element: <AccountStatusReports/>,
      },
      {
        path: '/Report/AdminCheckStatusReportByExcel',
        name: 'AdminCheckStatusReportByExcel',
        exact: true,
        element: <AdminCheckStatusReportByExcel/>,
      },
      
      {
        path: '/Reports/AccountReport/AdminBalanceAnalysisReport',
        name: 'AccountReport',
        exact: true,
        element: <AdminBalanceAnalysisReport/>,
      },
      {
        path: '/Reports/AccountReport/AdminDMTLogReport',
        name: 'AccountReport',
        exact: true,
        element: <AdminDMTLogReport/>,
      },
      {
        path: '/Reports/AccountReport/AdminVirificationChargeReport',
        name: 'AccountReport',
        exact: true,
        element: <AdminVirificationChargeReport/>,
      },
      
    ],
  },
  // SUPER DISTRIBUTOR SCREEN
  {
    path: '/',
    element: <ProtectRouteSuper Component={SuperLayout} />,
    children: [
      {
        path: '/dashboards/superdistributordashboard',
        name: 'superDistributor',
        exact: true,
        element: <SuperDistributorDashboard />,
      },
      {
        path: '/SuperDistributor/AddDocumentKyc',
        name: 'superDistributor',
        exact: true,
        element: <SuperDocumentKyc />,
      },
      {
        path: '/dashboards/SuperViewRetailerDistributor',
        name: 'superDistributor',
        exact: true,
        element: <SuperViewRetailerDistributor />,
      },
      {
        path: '/SuperDistributor/ProfileDetails',
        name: 'SuperDistributor',
        exact: true,
        element: <ProfileDetails />,
      },
      {
        path: '/SuperDistributor/UpdateProfileMenu',
        name: 'SuperDistributor',
        exact: true,
        element: <UpdateProfileMenu />,
      },
      {
        path: '/dashboards/SuperCreateRetailerDistributor',
        name: 'superDistributor',
        exact: true,
        element: <SuperCreateRetailerDistributor />,
      },
      {
        path: '/superDistributor/DistributorDocs',
        name: 'superDistributor',
        exact: true,
        element: <DistributorDocs />,
      },
      {
        path: '/dashboards/SuperManageCreditDebitDistributor',
        name: 'superDistributor',
        exact: true,
        element: <SuperManageCreditDebitDistributor />,
      },
      {
        path: '/dashboards/SuperCreditAmountToRetailer',
        name: 'superDistributor',
        exact: true,
        element: <SuperCreditAmountToRetailer />,
      },
      {
        path: '/dashboards/SuperCreditDetailsDistributor',
        name: 'superDistributor',
        exact: true,
        element: <SuperCreditDetailsDistributor />,
      },
      {
        path: '/dashboards/CommisionSuperReportDistributor',
        name: 'superDistributor',
        exact: true,
        element: <CommisionSuperReportDistributor />,
      },
      //  Reports
      {
        path: '/dashboards/LedgerSuperReportDistributor',
        name: 'superDistributor',
        exact: true,
        element: <LedgerSuperReportDistributor />,
      },
      {
        path: '/SuperDistributor/SuperDistributorBusinessReport',
        name: 'SuperDistributor',
        exact: true,
        element: <SuperDistributorBusinessReport/>,
      },
      {
        path: '/SuperDistributor/SuperDistributorOutStandingReports',
        name: 'SuperDistributor',
        exact: true,
        element: <SuperDistributorOutStandingReports />,
      },
      {
        path: '/SuperDistributor/DirectTopUpReport',
        name: 'SuperDistributor',
        exact: true,
        element: <DirectTopUpReport />,
      },
      
      // Profile
      {
        path: '/Profile/UpdateProfileSD',
        name: 'UpdateProfileSD',
        exact: true,
        element: <UpdateProfileSD />,
      },
      {
        path: '/SuperDistributor/ChangePasswordSP',
        name: 'ChangePasswordSP',
        exact: true,
        element: <ChangePasswordSP />,
      },

      // SELF BANK
      {
        path: '/SuperDistributor/AddNewBankSuperDistributor',
        name: 'AddNewBank',
        exact: true,
        element: <AddNewBankSuperDistributor />,
      },
      {
        path: '/SuperDistributor/SPDistributorBankList',
        name: 'SPDistributorBankList',
        exact: true,
        element: <SPDistributorBankList />,
      },
      {
        path: '/SuperDistributor/UpdateSPDistributorBank',
        name: 'UpdateSPDistributorBank',
        exact: true,
        element: <UpdateSPDistributorBank />,
      },
      {
        path: '/SuperDistributor/BalanceRequestHistorySuper',
        name: 'BalanceRequestHistorySuper',
        exact: true,
        element: <BalanceRequestHistorySuper />,
      },
      {
        path: '/SuperDistributor/NewBalanceRequestSuper',
        name: 'NewBalanceRequestSuper',
        exact: true,
        element: <NewBalanceRequestSuper />,
      },
    ],
  },

  // DISTRIBUTOR SCREEN

  {
    path: '/',
    element: <ProtectRouteDistributor Component={DistributorLayout} />,
    children: [
      {
        path: '/dashboards/distributordashboard',
        name: 'Distributor',
        exact: true,
        element: <DistributorDashboard />,
      },
      {
        path: '/Distributor/AddDocumentKyc',
        name: 'Distributor',
        exact: true,
        element: <DistributorDocsKyc />,
      },
      {
        path: '/Distributor/CreateRetailer',
        name: 'Distributor',
        exact: true,
        element: <CreateRetailerDistributor />,
      },
      {
        path: '/Distributor/ViewRetailerDistributor',
        name: 'Distributor',
        exact: true,
        element: <ViewRetailerDistributor />,
      },
      {
        path: '/Distributor/UpdateProfileMenu',
        name: 'Distributor',
        exact: true,
        element: <UpdateProfileMenu />,
      },
      {
        path: '/Distributor/RetailerDocs',
        name: 'Distributor',
        exact: true,
        element: <RetailerDocs />,
      },
      {
        path: '/Distributor/ManageCreditDebitDistributor',
        name: 'Distributor',
        exact: true,
        element: <ManageCreditDebitDistributor />,
      },
      {
        path: '/Distributor/CreditAmountToRetailer',
        name: 'Distributor',
        exact: true,
        element: <CreditAmountToRetailer />,
      },
      {
        path: '/Distributor/CreditDetailsDistributor',
        name: 'Distributor',
        exact: true,
        element: <CreditDetailsDistributor />,
      },
      {
        path: '/Profile/UpdateProfileDistributor',
        name: 'UpdateProfileDistributor',
        exact: true,
        element: <UpdateProfileDistributor />,
      },
      // Reports 
      {
        path: '/dashboards/LedgerReportDistributor',
        name: 'Distributor',
        exact: true,
        element: <LedgerReportDistributor />,
      },
      {
        path: '/Distributor/DistributorBusinessReport',
        name: 'Distributor',
        exact: true,
        element: <DistributorBusinessReport/>,
      },
      {
        path: '/Distributor/DistributorOutStandingReports',
        name: 'Distributor',
        exact: true,
        element: <DistributorOutStandingReports />,
      },
      {
        path: '/Distributor/DirectTopUpReports',
        name: 'Distributor',
        exact: true,
        element: <DirectTopUpReports />,
      },
      {
        path: '/Distributor/ChangePasswordDistributer',
        name: 'Distributor',
        exact: true,
        element: <ChangePasswordDistributer />,
      },
      {
        path: '/Distributor/ProfileDetails',
        name: 'Distributor',
        exact: true,
        element: <ProfileDetails />,
      },
      
      
      // BALANCE REQUEST
      {
        path: '/Distributor/BalanceRequestHistoryDistributor',
        name: 'Distributor',
        exact: true,
        element: <BalanceRequestHistoryDistributor />,
      },
      {
        path: '/Distributor/NewBalanceRequestDistributor',
        name: 'Distributor',
        exact: true,
        element: <NewBalanceRequestDistributor />,
      },
      // SELF BANK
      {
        path: '/Distributor/AddNewBankDistributor',
        name: 'Distributor',
        exact: true,
        element: <AddNewBankDistributor />,
      },
      {
        path: '/Distributor/DistributorBankList',
        name: 'Distributor',
        exact: true,
        element: <DistributorBankList />,
      },
      {
        path: '/Distributor/UpdateDistributorBank',
        name: 'Distributor',
        exact: true,
        element: <UpdateDistributorBank />,
      },
    ],
  },

  // RETAILER SCREEN
  {
    path: '/',
    element: <ProtectRouteAgent Component={FullLayout} />,
    children: [
      {
        path: '/dashboards/retailerdashboard',
        name: 'Retailer',
        exact: true,
        element: <RetailerDashboard />,
      },
      // Banking Services
      {
        path: '/DMT/DMTDashboard',
        name: 'DMTDashboard',
        exact: true,
        element: <DMTDashboard />,
      },
      {
        path: '/DMT/SearchCustomer',
        name: 'DMTDashboard',
        exact: true,
        element: <SearchCustomer />,
      },
      {
        path: '/DMT/AddCustomer',
        name: 'DMTDashboard',
        exact: true,
        element: <AddCustomer />,
      },
      {
        path: '/Benificiary/BenificiaryList',
        name: 'BenificiaryList',
        exact: true,
        element: <BenificiaryList />,
      },
      {
        path: '/Benificiary/AddBenificiary',
        name: 'AddBenificiary',
        exact: true,
        element: <AddBenificiary />,
      },
      {
        path: '/Benificiary/BenificiaryDetails',
        name: 'BenificiaryDetails',
        exact: true,
        element: <BenificiaryDetails />,
      },
      {
        path: '/DMT/TransactionReciept',
        name: 'TransactionReciept',
        exact: true,
        element: <TransactionReciept />,
      },
      // PAYOUT-1
      {
        path: '/Agent/SearchPayoutCustomer',
        name: 'PAYOUT',
        exact: true,
        element: <SearchPayoutCustomer />,
      },
      {
        path: '/Agent/AddCustomerPayout',
        name: 'PAYOUT',
        exact: true,
        element: <AddCustomerPayout />,
      },
      {
        path: '/Agent/PayAddBeneficiary',
        name: 'PAYOUT',
        exact: true,
        element: <PayAddBeneficiary />,
      },  
      {
        path: '/Agent/PayoutBeneficiaryList',
        name: 'PAYOUT',
        exact: true,
        element: <PayoutBeneficiaryList />,
      },
      {
        path: '/Agent/PayOutBeniDetails',
        name: 'PAYOUT',
        exact: true,
        element: <PayOutBeniDetails />,
      },
      {
        path: '/SelfBank/RetailorBankList',
        name: 'RetailorBankList',
        exact: true,
        element: <RetailorBankList />,
      },
      {
        path: '/SelfBank/AddNewBank',
        name: 'AddNewBank',
        exact: true,
        element: <AddNewBank />,
      },
      {
        path: '/SelfBank/UpdateRetailorBank',
        name: 'UpdateRetailorBank',
        exact: true,
        element: <UpdateRetailorBank />,
      },
      {
        path: '/Reports/AllRetailorReports',
        name: 'AllRetailorReports',
        exact: true,
        element: <AllRetailorReports />,
      },
      // profile
      {
        path: '/Profile/UpdateProfileAgent',
        name: 'UpdateProfileAgent',
        exact: true,
        element: <UpdateProfileAgent />,
      },
      {
        path: '/Agent/SetTPIN',
        name: 'SetTPIN',
        exact: true,
        element: <SetTPIN />,
      },
      {
        path: '/Agent/ResetTPIN',
        name: 'ResetTPIN',
        exact: true,
        element: <ResetTPIN />,
      },
      
      {
        path: '/Agent/ChangePassword',
        name: 'ChangePassword',
        exact: true,
        element: <ChangePassword />,
      },

      // Balance Request
      {
        path: '/Agent/BalanceRequestHistory',
        name: 'BalanceRequestHistory',
        exact: true,
        element: <BalanceRequestHistory />,
      },
      {
        path: '/Agent/NewBalanceRequest',
        name: 'NewBalanceRequest',
        exact: true,
        element: <NewBalanceRequest />,
      },
      // Comlaint
      {
        path: '/Agent/ComplaintListAgent',
        name: 'ComplaintListAgent',
        exact: true,
        element: <ComplaintListAgent />,
      },
      {
        path: '/Agent/ComplaintAddAgent',
        name: 'ComplaintAddAgent',
        exact: true,
        element: <ComplaintAddAgent />,
      },
      // Bill Paymnet

      {
        path: '/Agent/BillPaymentsBa',
        name: 'BillPayments',
        exact: true,
        element: <BillPaymentsBa />,
      },
      {
        path: '/Agent/DthRecharge',
        name: 'DTH',
        exact: true,
        element: <DTHRecharge />,
      },
      {
        path: '/Agent/MobileRecharge',
        name: 'mobile',
        exact: true,
        element: <MobileRecharge />,
      },
      {
        path: '/Agent/PayementReciept',
        name: 'mobile',
        exact: true,
        element: <PayementReciept />,
      },
      {
        path: '/Retailer/AddDocumentKyc',
        name: 'AddDocumentKyc',
        exact: true,
        element: <AddDocumentKyc />,
      },
      // AEPS SCREEN
      {
        path: '/Agent/AEPSOnboarding',
        name: 'AEPS',
        exact: true,
        element: <AEPSOnboarding />,
      },
      {
        path: '/Agent/AepsSearch',
        name: 'AEPS',
        exact: true,
        element: <AepsSearch />,
      },
      {
        path: '/Agent/AEPSCaptureIs',
        name: 'AEPS',
        exact: true,
        element: <AEPSCaptureIs />,
      },
      {
        path: '/Agent/OnboardingUser',
        name: 'AEPS',
        exact: true,
        element: <OnboardingUser />,
      },
      {
        path: '/Agent/AEPSSearchPs',
        name: 'AEPS',
        exact: true,
        element: <AEPSSearchPs />,

      },
      {
        path: '/Agent/AEPSDailyAuthantication',
        name: 'AEPS',
        exact: true,
        element: <AEPSDailyAuthantication />,

      },
      {
        path: '/Agent/AEPSRegistrationUser',
        name: 'AEPS',
        exact: true,
        element: <AEPSRegistrationUser />,
      },
      {
        path: '/Agent/AEPSWithdralCapture',
        name: 'AEPS',
        exact: true,
        element: <AEPSWithdralCapture />,
      },
      {
        path: '/Agent/AEPSBalAndMinistatment',
        name: 'AEPS',
        exact: true,
        element: <AEPSBalAndMinistatment />,
      },
      {
        path: '/Agent/AEPSTransactionSelect',
        name: 'AEPS',
        exact: true,
        element: <AEPSTransactionSelect />,
      },
      {
        path: '/Agent/AEPSTransactionReciept',
        name: 'AEPS',
        exact: true,
        element: <AEPSTransactionReciept />,
      },
      {
        path: '/Agent/AEPSToDMTTransfer',
        name: 'AEPS',
        exact: true,
        element: <AEPSToDMTTransfer />,
      },
      {
        path: '/Agent/AEPSPayoutBeneficiaryDetails',
        name: 'AEPS',
        exact: true,
        element: <AEPSPayoutBeneficiaryDetails />,
      },
      {
        path: '/Agent/AEPSPayout',
        name: 'AEPS',
        exact: true,
        element: <AEPSPayout />,
      },
      // AEPS EKO
      {
        path: '/Agent/AEPSSearchek',
        name: 'AEPS',
        exact: true,
        element: <AEPSSearchEk />,
      },
      {
        path: '/Agent/OnboardingUserek',
        name: 'AEPS',
        exact: true,
        element: <OnboardingUserEko />,
      },
      {
        path: '/Agent/ServiceActivationek',
        name: 'AEPS',
        exact: true,
        element: <ServiceActivationEko />,
      },
      {
        path: '/Agent/AepsRegistration',
        name: 'AEPS',
        exact: true,
        element: <AepsRegistration />,
      },
      {
        path: '/Agent/AepsDailyAuthantication',
        name: 'AEPS',
        exact: true,
        element: <AepsDailyAuthantication />,
      },
      // {
      //   path: '/Agent/AEPSTransactionReports',
      //   name: 'AEPS',
      //   exact: true,
      //   element: <AEPSTransactionReports />,
      // },
      // REPORTS
      {
        path: '/Agent/AdminChangeStatus',
        name: 'AgentChangeStatus',
        exact: true,
        element: <AdminChangeStatus />,
      },
      {
        path: '/Agent/LedgerReport',
        name: 'LedgerReport',
        exact: true,
        element: <LedgerReport />,
      },
      {
        path: '/Agent/AEPSLedgerReport',
        name: 'AEPSLedgerReport',
        exact: true,
        element: <AEPSLedgerReport />,
      },
      {
        path: '/Agent/AccountStatusReports',
        name: 'AccountStatusReports',
        exact: true,
        element: <AgentAccountStatusReports/>,
      },
      {
        path: '/Agent/DMTTransactionReports',
        name: 'DMTTransactionReports',
        exact: true,
        element: <DMTTransactionReports />,
      },
      {
        path: '/Agent/MoneyTransferHoldCases',
        name: 'MoneyTransferHoldCases',
        exact: true,
        element: <MoneyTransferHoldCases />,
      },
      {
        path: '/Agent/TransactionByAccountNumber',
        name: 'GetTransactionByAccountNumber',
        exact: true,
        element: <GetTransactionByAccountNumber />,
      },
      {
        path: '/Agent/AEPSTransactionReports',
        name: 'AEPSTransactionReports',
        exact: true,
        element: <AEPSTransactionReports />,
      },
      {
        path: '/Agent/TDSReports',
        name: 'TDSReports',
        exact: true,
        element: <TDSReports />,
      },
      {
        path: '/Agent/GSTReports',
        name: 'GSTReports',
        exact: true,
        element: <GSTReports />,
      },
      {
        path: '/Agent/TopUpReports',
        name: 'TopUpReports',
        exact: true,
        element: <TopUpReports />,
      },
      {
        path: '/Agent/OutStandingReports',
        name: 'TopUpReports',
        exact: true,
        element: <OutStandingReports />,
      },
      {
        path: '/Agent/PaymentGetwayReports',
        name: 'PaymentGetwayReports',
        exact: true,
        element: <PaymentGetwayReports />,
      },
      {
        path: '/Agent/BBPSPaymentsReports',
        name: 'BBPSPaymentsReports',
        exact: true,
        element: <BBPSPaymentsReports />,
      },
      {
        path: '/Agent/TransactionByConsumerNo',
        name: 'GetTransactionByConsumerNo',
        exact: true,
        element: <GetTransactionByConsumerNo />,
      },
      // PG
      {
        path: '/Agent/PaymentGetway',
        name: 'PG',
        exact: true,
        element: <PaymentGetway />,
      },
    ],
  },
  // BUSINESS SCREEN
  {
    path: '/',
    element: <ProtectRouteBusiness Component={BusinessLayout} />,
    children: [
      {
        path: '/Dashboards/BusinessPartnerDashboard',
        name: 'Business',
        exact: true,
        element: <BusinessPartnerDashboard />,
      },
      // Balance Request CreateBalanceRequest
      {
        path: '/Business/BalanceRequestHistoryBusiness',
        name: 'Business',
        exact: true,
        element: <BalanceRequestHistoryBusiness />,
      },
      {
        path: '/Business/CreateBalanceRequest',
        name: 'Business',
        exact: true,
        element: <CreateBalanceRequest />,
      },
      // self Bank
      {
        path: '/Business/BusinessBankList',
        name: 'Business',
        exact: true,
        element: <BusinessBankList />,
      },
      {
        path: '/Business/AddBusinessBank',
        name: 'Business',
        exact: true,
        element: <AddBusinessBank />,
      },
      {
        path: '/Business/UpdateBusinessBank',
        name: 'Business',
        exact: true,
        element: <UpdateBusinessBank />,
      },
      {
        path: '/Profile/UpdateProfileBusiness',
        name: 'Business',
        exact: true,
        element: <UpdateProfileBusiness />,
      },
      {
        path: '/Business/ChangePasswordBD',
        name: 'Business',
        exact: true,
        element: <ChangePasswordBD />,
      },
      
    ],
  },
  // MASTER SCREEN
  {
    path: '/',
    element: <ProtectRouteMaster Component={MasterLayout} />,
    children: [
      {
        path: '/Dashboards/MasterDistributorDashboard',
        name: 'MasterDistributor',
        exact: true,
        element: <MasterDistributorDashboard />,
      },
      {
        path: '/MasterDistributor/AddDocumentKyc',
        name: 'MasterDocumentKyc',
        exact: true,
        element: <MasterDocumentKyc />,
      },
      //Balance Request
      {
        path: '/MasterDistributor/NewBalanceRequestMaster',
        name: 'MasterDistributor',
        exact: true,
        element: <NewBalanceRequestMaster />,
      },
      {
        path: '/dashboards/MasterViewSuperDistributor',
        name: 'MasterDistributor',
        exact: true,
        element: <MasterViewSuperDistributor />,
      },
      {
        path: '/dashboards/MasterCreateSuperDistributor',
        name: 'MasterDistributor',
        exact: true,
        element: <MasterCreateSuperDistributor />,
      },
      {
        path: '/MasterDistributor/SuperDistributorDocs',
        name: 'MasterDistributor',
        exact: true,
        element: <SuperDistributorDocs />,
      },
      {
        path: '/MasterDistributor/BalanceRequestHistoryMaster',
        name: 'MasterDistributor',
        exact: true,
        element: <BalanceRequestHistoryMaster />,
      },
      {
        path: '/dashboards/MasterManageCreditDebitDistributor',
        name: 'superDistributor',
        exact: true,
        element: <MasterManageCreditDebitDistributor />,
      },
      {
        path: '/dashboards/MasterCreditAmountToRetailer',
        name: 'superDistributor',
        exact: true,
        element: <MasterCreditAmountToRetailer />,
      },
      //credit details 
      {
        path: '/MasterDistributor/MasterCreditDetails',
        name: 'MasterDistributor',
        exact: true,
        element: <MasterCreditDetails />,
      },
      // reports
      {
        path: '/dashboards/LedgerMasterReportDistributor',
        name: 'MasterDistributor',
        exact: true,
        element: <LedgerMasterReportDistributor />,
      },
      // self bank
      {
        path: '/MasterDistributor/MasterBankList',
        name: 'MasterDistributor',
        exact: true,
        element: <MasterBankList />,
      },
      {
        path: '/MasterDistributor/AddMasterBank',
        name: 'MasterDistributor',
        exact: true,
        element: <AddMasterBank />,
      },
      {
        path: '/MasterDistributor/UpdateMasterBank',
        name: 'MasterDistributor',
        exact: true,
        element: <UpdateMasterBank />,
      },
      {
        path: '/MasterDistributor/ChangePasswordMd',
        name: 'MasterDistributor',
        exact: true,
        element: <ChangePasswordMd />,
      },
      {
        path: '/Profile/ProfileUpdateMD',
        name: 'MasterDistributor',
        exact: true,
        element: <UpdateProfileMD />,
      },
    ],
  },
  //EMPLOYE SCREEN
  {
    path: '/',
    element: <ProtectRouteEmploye Component={EmployeLayout} />,
    children: [
      {
        path: '/Dashboards/EmployeeDashboard',
        name: 'Employee',
        exact: true,
        element: <EmployeeDashboard />,
      },
      // Balance Request NewBalanceRequestEmployee BalanceRequestHistoryEmployee
      {
        path: '/Employee/NewBalanceRequestEmployee',
        name: 'Employee',
        exact: true,
        element: <NewBalanceRequestEmployee />,
      },
      {
        path: '/Employee/BalanceRequestHistoryEmployee',
        name: 'Employee',
        exact: true,
        element: <BalanceRequestHistoryEmployee />,
      },
      // Self Bank EmployeeBankList AddEmployeeBank UpdateEmployeeBank
      {
        path: '/Employee/EmployeeBankList',
        name: 'Employee',
        exact: true,
        element: <EmployeeBankList />,
      },
      {
        path: '/Employee/AddEmployeeBank',
        name: 'Employee',
        exact: true,
        element: <AddEmployeeBank />,
      },
      {
        path: '/Employee/UpdateEmployeeBank',
        name: 'Employee',
        exact: true,
        element: <UpdateEmployeeBank />,
      },
      {
        path: '/Employee/ChangePasswordEMP',
        name: 'Employee',
        exact: true,
        element: <ChangePasswordEMP />,
      },
    ],
  },
  // SUPER ADMIN SCREEN
  {
    path: '/',
    element: <ProtectRouteSuperAdmin Component={SuperAdminLayout} />,
    children: [
      {
        path: '/Dashboards/SuperAdminDashboard',
        name: 'SuperAdmin',
        exact: true,
        element: <SuperAdminDashboard />,
      },
      {
        path: '/SuperAdmin/AddApi',
        name: 'ApiMaster',
        exact: true,
        element: <AddApi />,
      },
      {
        path: '/SuperAdmin/AllApi',
        name: 'ApiMaster',
        exact: true,
        element: <AllApi />,
      },
      {
        path: '/SuperAdmin/UpdateApi',
        name: 'ApiMaster',
        exact: true,
        element: <UpdateApi />,
      },
      {
        path: '/SuperAdmin/CreateCommissionMasterTemplate',
        name: 'CommissionMaster',
        exact: true,
        element: <CreateCommissionMasterTemplate />,
      },
      {
        path: '/SuperAdmin/GetCommissionMasterTemplate',
        name: 'CommissionMaster',
        exact: true,
        element: <GetCommissionMasterTemplate />,
      },
      
      {
        path: '/SuperAdmin/AllServices',
        name: 'ServiceMaster',
        exact: true,
        element: <AllServices />,
      },
      {
        path: '/SuperAdmin/AddServices',
        name: 'ServiceMaster',
        exact: true,
        element: <AddServices />,
      },
    ],
  },
];

export default ThemeRoutes;
